import { onMounted, watch } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';

/**
 *
 * @param {import('vue').WatchSource} source One, or an array of, watch sources
 * @param {import('vue').WatchCallback} callback The callback for when the source(s) change
 * @param {import('vue').WatchOptions} options Watch options
 */
export function propWatch(source, callback, options) {
	let unwatch = () => { };
	onMounted(() => {
		unwatch = watch(source, callback, options);
	});
	onBeforeRouteLeave(() => {
		unwatch();
	});
}
